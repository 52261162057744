(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/competition.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/competition.js');
"use strict";


let tipsenShared;
let getCmsConfig;
let competitionHandler;
if (svs.isServer) {
  tipsenShared = require('../../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6');
  getCmsConfig = require('../../../get-cms-config/assets/javascripts/utils.es6').getCmsConfig;
  competitionHandler = require('../../../../marketplace-competition/competition-handler/competition-handler.es6');
} else {
  tipsenShared = svs.components.sport.tipsenShared;
  getCmsConfig = svs.components.lbUtils.getCmsConfig.utils.getCmsConfig;
  competitionHandler = svs.components.marketplaceCompetition.competitionHandler.competitionHandler;
}

const {
  ClientSubProductType
} = tipsenShared;

const getCompetitionData = (clientProductType, reqProductId) => {
  const cmsConfig = getCmsConfig();
  const competitions = cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.competitions;
  let competitionId;
  if (competitions !== null && competitions !== void 0 && competitions.length) {
    const compHandler = competitionHandler();
    const activeCompetition = compHandler.getActiveCompetition(reqProductId);
    competitionId = activeCompetition === null || activeCompetition === void 0 ? void 0 : activeCompetition.getCompetitionId();
  }
  const hasCompetitionSubtype = Boolean(clientProductType === ClientSubProductType.TIPS_SM) || Boolean(clientProductType === ClientSubProductType.TIPS_EM);
  const hasCompetition = competitionId || competitionId === 0;
  if (hasCompetitionSubtype && hasCompetition) {
    return {
      competitionId: Number(competitionId)
    };
  }
  return {};
};

const toExport = {
  getCompetitionData
};

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.competition.fn', toExport);
}


 })(window);