(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/brand-mapping.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/brand-mapping.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

let utils;
let getCompiledBrand;
let getCompetitionData;
if (svs.isServer) {
  getCompiledBrand = require('./compile-brand.es6');
  getCompetitionData = require('./competition.es6').getCompetitionData;
  utils = require('./utils.es6');
} else {
  getCompiledBrand = svs.components.lbUtils.compileBrand.getCompiledBrand;
  utils = svs.components.lbUtils.brandMapping.utils;
  getCompetitionData = svs.components.lbUtils.competition.fn.getCompetitionData;
}

const {
  getSubProductType,
  getSubProductTypeByProductId,
  filterProductKeys
} = utils;

const getProductConfigData = function (reqProductId) {
  let reqSubProductId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let reqUserProduct = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  if (reqSubProductId) {
    reqSubProductId = Number(reqSubProductId);
  }
  const clientProductType = getSubProductTypeByProductId(reqProductId, reqSubProductId, reqUserProduct);
  const subProductType = getSubProductType(reqProductId, reqSubProductId, clientProductType);
  const [productId, clientSubProductType] = filterProductKeys(reqProductId, subProductType);
  const compiledBrand = getCompiledBrand(productId, clientSubProductType);
  const competitionData = getCompetitionData(clientProductType, reqProductId);
  return _objectSpread(_objectSpread({}, compiledBrand), competitionData);
};
const getProductConfigDataByDraw = function (draw) {
  let productId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return getProductConfigData((draw === null || draw === void 0 ? void 0 : draw.productId) || productId, draw === null || draw === void 0 ? void 0 : draw.subProductId, draw === null || draw === void 0 ? void 0 : draw.userProduct);
};
const getProductConfigDataField = (productId, subProductId, userProduct, field) => {
  const configData = getProductConfigData(productId, subProductId, userProduct);
  return !configData.unknownProduct ? configData[field] : undefined;
};
const getProductConfigByDrawDataField = (draw, productId, field) => {
  const configData = getProductConfigDataByDraw(draw, productId);
  return !configData.unknownProduct ? configData[field] : undefined;
};
const getSiteBrand = function (productId, subProductId) {
  let userProduct = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return getProductConfigDataField(productId, subProductId, userProduct, 'brandName');
};
const getProductBrand = function (productId, subProductId) {
  let userProduct = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return getProductConfigDataField(productId, subProductId, userProduct, 'brandLogo');
};
const getProductBrands = function (productId, subProductId) {
  let userProduct = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return getProductConfigDataField(productId, subProductId, userProduct, 'brand');
};
const getProductDisplayName = function (productId, subProductId) {
  let userProduct = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return getProductConfigDataField(productId, subProductId, userProduct, 'displayName');
};
const getProductBrandMenuColor = function (productId, subProductId) {
  let userProduct = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return getProductConfigDataField(productId, subProductId, userProduct, 'brandMenuColor');
};
const getProductCompetitionId = function (productId, subProductId) {
  let userProduct = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return getProductConfigDataField(productId, subProductId, userProduct, 'competitionId');
};
const getSiteBrandByDraw = function (draw) {
  let productId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return getProductConfigByDrawDataField(draw, productId, 'brandName');
};
const getProductBrandByDraw = function (draw) {
  let productId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return getProductConfigByDrawDataField(draw, productId, 'brandLogo');
};
const getProductBrandsByDraw = function (draw) {
  let productId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return getProductConfigByDrawDataField(draw, productId, 'brand');
};
const getProductDisplayNameByDraw = function (draw) {
  let productId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return getProductConfigByDrawDataField(draw, productId, 'displayName');
};
const getProductBrandMenuColorByDraw = function (draw) {
  let productId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return getProductConfigByDrawDataField(draw, productId, 'brandMenuColor');
};

const toExport = {
  getProductConfigData,
  getSiteBrand,
  getProductBrand,
  getProductBrands,
  getProductDisplayName,
  getProductBrandMenuColor,
  getProductCompetitionId,
  getProductConfigDataByDraw,
  getSiteBrandByDraw,
  getProductBrandByDraw,
  getProductBrandsByDraw,
  getProductDisplayNameByDraw,
  getProductBrandMenuColorByDraw
};

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.brandMapping.fn', toExport);
}


 })(window);