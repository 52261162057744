(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/brands/base-brand.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/brands/base-brand.js');
"use strict";

const model = {
  brand: '',
  displayName: '',
  brandLogo: '',
  brandMenuColor: '',
  unknownProduct: true
};
const toExport = model;

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.brandMapping.brands.base', toExport);
}


 })(window);