(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/brands/product/casino.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/brands/product/casino.js');
"use strict";

let sportProducts;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  sportProducts = trinidad.components.require('lb-utils', 'sport-products').api;
} else {
  sportProducts = svs.components.lbUtils.sportProducts;
}

const {
  productIds,
  getDisplayName,
  getClassName
} = sportProducts;

const model = {
  brand: getClassName(productIds.CASINO),
  displayName: getDisplayName(productIds.CASINO),
  brandLogo: 'casino',
  brandMenuColor: 'white'
};

const toExport = model;

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.brandMapping.brands.product.casino', toExport);
}


 })(window);