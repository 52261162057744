(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/brands/subproduct/tips-sm.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/brands/subproduct/tips-sm.js');
"use strict";

const model = {
  brand: 'tips-sm'
};

const toExport = model;

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.brandMapping.brands.subproduct.tipsSm', toExport);
}


 })(window);