(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/brands/subproduct/em-tipset.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/brands/subproduct/em-tipset.js');
"use strict";

let tipsenShared;
if (svs.isServer) {
  tipsenShared = require('../../../../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6');
} else {
  tipsenShared = svs.components.sport.tipsenShared;
}

const {
  getSubProductDisplayName,
  SubProductId
} = tipsenShared;

const model = {
  brand: 'extratipset',
  displayName: getSubProductDisplayName(SubProductId.EM_TIPSET),
  brandLogo: 'em-tipset',
  brandMenuColor: 'white'
};

const toExport = model;

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.brandMapping.brands.subproduct.emTipset', toExport);
}


 })(window);