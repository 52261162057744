(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/enum.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/enum.js');
"use strict";


const SubProductBrand = {
  NONE: '',
  VM_TIPSET: 'vm-tipset',
  EM_TIPSET: 'em-tipset',
  VM_EUROPA: 'vm-tipset-europa',
  EM_EUROPA: 'em-tipset-europa',
  TIPS_SM: 'tips-sm',
  TIPS_EM: 'tips-em'
};

const toExport = {
  SubProductBrand
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.brandMapping.enum', toExport);
}


 })(window);