(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/brands/product/topptipset-stryk.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/brands/product/topptipset-stryk.js');
"use strict";

let products;
if (svs.isServer) {
  products = require('trinidad-core').core.components.require('utils', 'products');
} else {
  products = svs.utils.products;
}

const {
  getDisplayName,
  getProductClassName,
  productIds
} = products;

const model = {
  brand: getProductClassName(productIds.T8STRYK),
  displayName: getDisplayName(productIds.T8STRYK),
  brandLogo: 'topptipset',
  brandMenuColor: 'white'
};

const toExport = model;

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.brandMapping.brands.product.topptipsetStryk', toExport);
}


 })(window);