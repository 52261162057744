(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/brand-mapping/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/lb-utils/brand-mapping/assets/javascripts/utils.js');
"use strict";


let tipsenShared;
let products;
let getCmsConfig;
let competitionHandler;
let competitionType;
if (svs.isServer) {
  tipsenShared = require('../../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6');
  products = require('trinidad-core').core.components.require('utils', 'products');
  getCmsConfig = require('../../../get-cms-config/assets/javascripts/utils.es6').getCmsConfig;
  competitionHandler = require('../../../../marketplace-competition/competition-handler/competition-handler.es6');
  competitionType = require('../../../../marketplace-competition/constants/assets/javascripts/enum.es6').CompetitionType;
} else {
  tipsenShared = svs.components.sport.tipsenShared;
  products = svs.utils.products;
  getCmsConfig = svs.components.lbUtils.getCmsConfig.utils.getCmsConfig;
  competitionHandler = svs.components.marketplaceCompetition.competitionHandler.competitionHandler;
  competitionType = svs.components.marketplaceCompetition.constants.CompetitionType;
}

const {
  productIds
} = products;
const {
  SubProductId,
  subProductId2ToDisplayName,
  userProduct2SubProductId,
  ClientSubProductType,
  UserProduct
} = tipsenShared;

const getDateString = date => date.toISOString().split('T')[0];
const getCompetition = productId => {
  const cmsConfig = getCmsConfig();
  const competitions = cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.competitions;
  let competitionId;
  let compType;
  if (competitions !== null && competitions !== void 0 && competitions.length) {
    const compHandler = competitionHandler();
    const activeCompetition = compHandler.getActiveCompetition(productId);
    competitionId = activeCompetition === null || activeCompetition === void 0 ? void 0 : activeCompetition.getCompetitionId();
    compType = activeCompetition === null || activeCompetition === void 0 ? void 0 : activeCompetition.getCompType();
  }
  if (competitionId) {
    if (compType) {
      return compType === competitionType.TipsEM ? ClientSubProductType.TIPS_EM : ClientSubProductType.TIPS_SM;
    }
    return ClientSubProductType.TIPS_SM;
  }
  return null;
};
const getStryktipsetUserProduct = productId => {
  const competition = getCompetition(productId);
  return competition;
};
const getEuropaTipsetUserProduct = (subProductId, userProduct, productId) => {
  var _svs$brandMapping;
  const cmsConfig = getCmsConfig();
  const competition = getCompetition(productId);

  const cmsConfigExtratipset = (cmsConfig === null || cmsConfig === void 0 ? void 0 : cmsConfig.extratipset) || ((_svs$brandMapping = svs.brandMapping) === null || _svs$brandMapping === void 0 || (_svs$brandMapping = _svs$brandMapping.data) === null || _svs$brandMapping === void 0 ? void 0 : _svs$brandMapping.extratipsetCms) || {};
  const {
    active,
    productName,
    startDate,
    stopDate
  } = cmsConfigExtratipset || {};
  let cmsUserProduct = null;
  if (active === 'on' && productName && startDate && stopDate) {
    try {
      const today = getDateString(new Date());
      const start = getDateString(new Date(startDate));
      const stop = getDateString(new Date(stopDate));
      if (start <= today && today <= stop) {
        if (!userProduct && !subProductId || subProductId === SubProductId.VM_TIPSET && productName === ClientSubProductType.VM_EUROPA || subProductId === SubProductId.EM_TIPSET && productName === ClientSubProductType.EM_EUROPA || userProduct === UserProduct.VmTipset && productName === ClientSubProductType.VM_EUROPA || userProduct === UserProduct.EmTipset && productName === ClientSubProductType.EM_EUROPA) {
          cmsUserProduct = productName;
        }
      }
    } catch (_unused) {}
  }
  return cmsUserProduct || userProduct || competition;
};
const getSubProductTypeByProductId = (productId, subProductId, userProduct) => {
  let subProductType;
  switch (productId) {
    case productIds.STRYKTIPSET:
      subProductType = getStryktipsetUserProduct(productId);
      break;
    case productIds.EUROPATIPSET:
      subProductType = getEuropaTipsetUserProduct(subProductId, userProduct, productId);
      break;
    default:
      subProductType = null;
  }
  return subProductType;
};
const turnUserProductIntoSubProductId = userProduct => {
  let subProductId1;
  let subProductId2;
  if (userProduct) {
    subProductId1 = Object.keys(subProductId2ToDisplayName).find(key => subProductId2ToDisplayName[key] === userProduct);
    subProductId2 = userProduct2SubProductId[userProduct];
  }
  return subProductId1 || subProductId2 ? Number(subProductId1 || subProductId2) : null;
};
const getSubProductId = (subProductId, userProduct) => subProductId || turnUserProductIntoSubProductId(userProduct);
const filterProductTypeAccordingToProductId = (productId, productType) => {
  let productTypeFilter;
  switch (productId) {
    case productIds.STRYKTIPSET:
      productTypeFilter = [ClientSubProductType.TIPS_SM];
      break;
    case productIds.EUROPATIPSET:
      productTypeFilter = [ClientSubProductType.VM_TIPSET, ClientSubProductType.EM_TIPSET, ClientSubProductType.EM_EUROPA, ClientSubProductType.VM_EUROPA, ClientSubProductType.TIPS_SM, ClientSubProductType.TIPS_EM];
      break;
    default:
      productTypeFilter = [];
      break;
  }
  return productTypeFilter.includes(productType) ? productType : ClientSubProductType.NONE;
};
const getSubProductType = (productId, subProductId, clientProductType) => {
  let productType;
  switch (clientProductType) {
    case ClientSubProductType.VM_TIPSET:
      productType = ClientSubProductType.VM_TIPSET;
      break;
    case ClientSubProductType.EM_TIPSET:
      productType = ClientSubProductType.EM_TIPSET;
      break;
    case ClientSubProductType.VM_EUROPA:
      productType = ClientSubProductType.VM_EUROPA;
      break;
    case ClientSubProductType.EM_EUROPA:
      productType = ClientSubProductType.EM_EUROPA;
      break;
    case ClientSubProductType.TIPS_SM:
      productType = ClientSubProductType.TIPS_SM;
      break;
    case ClientSubProductType.TIPS_EM:
      productType = ClientSubProductType.TIPS_EM;
      break;
    case ClientSubProductType.EUROPATIPSET:
      productType = ClientSubProductType.EUROPATIPSET;
      break;
    default:
      productType = ClientSubProductType.NONE;
      break;
  }
  if (productType === ClientSubProductType.NONE) {
    const _subProductId = getSubProductId(subProductId, clientProductType);
    switch (_subProductId) {
      case SubProductId.VM_TIPSET:
        productType = ClientSubProductType.VM_TIPSET;
        break;
      case SubProductId.EM_TIPSET:
        productType = ClientSubProductType.EM_TIPSET;
        break;
      default:
        productType = ClientSubProductType.NONE;
        break;
    }
  }
  return filterProductTypeAccordingToProductId(productId, productType);
};
const filterProductKeys = (reqSubProductId, subProductType) => {
  let productId;
  let clientProductType;
  switch (reqSubProductId) {
    case productIds.EUROPATIPSET:
      if (subProductType === ClientSubProductType.EUROPATIPSET) {
        productId = productIds.EUROPATIPSET;
        clientProductType = ClientSubProductType.NONE;
      } else {
        productId = reqSubProductId;
        clientProductType = subProductType;
      }
      break;
    default:
      productId = reqSubProductId;
      clientProductType = subProductType;
      break;
  }
  return [productId, clientProductType];
};

const toExport = {
  getSubProductTypeByProductId,
  getSubProductType,
  filterProductKeys
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.brandMapping.utils', toExport);
}


 })(window);